
import { useLabels } from '@/composables/user/useLabels'
import { defineComponent, onMounted, ref } from 'vue'
import UserTagsModal from '../tags/UserTagsModal.vue'

interface ILabel {
  id: string;
  text: string;
  color: string;
  isUsed?: boolean;
}

export default defineComponent({
  components: { UserTagsModal },

  setup () {
    const { getTags } = useLabels()

    const labels = ref<ILabel[]>([])

    onMounted( async () => {
      const userTags = await getTags()
      labels.value = userTags
        .map((tag: ILabel) => ({
          ...tag,
          isUsed: false
        }))
    })

    return {
      labels
    }
  }
})
