<template>
  <div class="card">
    <div class="card-header">
      <div>
        <div class="card-title">{{ $t('page.tags.action.open_modal') }}</div>
        <small>{{ $t('page.tags.sentence.description') }}</small>
      </div>
    </div>
    <div class="card-body">
      <div
        class="flex flex-row flex-wrap items-center w-full gap-2 justify-start"
      >
        <div
          v-for="tag in labels"
          :key="tag.id"
          class="rounded w-10 min-w-max h-6 py-1 px-2 flex justify-center items-center text-white text-xs font-semibold"
          :class="`tag-${tag.color}`"
        >
          {{ tag.text }}
        </div>
      </div>
    </div>
    <div class="card-footer flex flex-row flex-wrap justify-end items-center">
      <user-tags-modal>
        <slot name="button">
          <button
            type="button"
            class="btn btn-primary sm:text-sm"
            >
            {{ $t('action.edit') }}
          </button>
        </slot>
      </user-tags-modal>
    </div>
  </div>

</template>

<script lang="ts">
import { useLabels } from '@/composables/user/useLabels'
import { defineComponent, onMounted, ref } from 'vue'
import UserTagsModal from '../tags/UserTagsModal.vue'

interface ILabel {
  id: string;
  text: string;
  color: string;
  isUsed?: boolean;
}

export default defineComponent({
  components: { UserTagsModal },

  setup () {
    const { getTags } = useLabels()

    const labels = ref<ILabel[]>([])

    onMounted( async () => {
      const userTags = await getTags()
      labels.value = userTags
        .map((tag: ILabel) => ({
          ...tag,
          isUsed: false
        }))
    })

    return {
      labels
    }
  }
})
</script>
